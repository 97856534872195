import * as THREE from "three";
import Experience from "./Experience.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";

export default class Camera {
  constructor(_options) {
    // Options
    this.experience = new Experience();
    this.config = this.experience.config;
    this.debug = this.experience.debug;
    this.time = this.experience.time;
    this.sizes = this.experience.sizes;
    this.targetElement = this.experience.targetElement;
    this.scene = this.experience.scene;

    // Set up
    this.mode = "debug"; // defaultCamera \ debugCamera

    this.setInstance();
    this.setModes();
  }

  setInstance() {
    // Set up
    this.instance = new THREE.PerspectiveCamera(
      70,
      this.config.width / this.config.height,
      0.01,
      1500
    );
    this.instance.rotation.reorder("YXZ");

    this.scene.add(this.instance);
  }

  setModes() {
    this.modes = {};

    // Default
    this.modes.default = {};
    this.modes.default.instance = this.instance.clone();
    this.modes.default.instance.rotation.reorder("YXZ");
    this.largeTextElement = document.getElementById('largeText');
    this.updateLargeText();
    // Debug
    this.modes.debug = {};
    this.modes.debug.instance = this.instance.clone();
    this.modes.debug.instance.rotation.reorder("YXZ");
    this.modes.debug.instance.position.set(-0.5, 0, 0);

    this.modes.debug.orbitControls = new OrbitControls(
      this.modes.debug.instance,
      this.targetElement
    );
    this.modes.debug.orbitControls.enabled = this.modes.debug.active;
    this.modes.debug.orbitControls.screenSpacePanning = true;
    this.modes.debug.orbitControls.enableKeys = false;
    this.modes.debug.orbitControls.zoomSpeed = 0.25;
    this.modes.debug.orbitControls.enableDamping = true;
    this.modes.debug.orbitControls.autoRotate = true; // Enable auto rotation
    this.modes.debug.orbitControls.autoRotateSpeed = 1; // Set auto rotation speed
    this.modes.debug.orbitControls.minDistance = 0.5; // Set minimum distance for zoom
    this.modes.debug.orbitControls.maxDistance = 5; // Set maximum distance for zoom
    this.modes.debug.orbitControls.update();
  }
  updateLargeText() {
    this.largeTextElement.textContent = 'Exploration and Discovery'; // 设置大字体的内容
    this.largeTextElement.style.color = '#ffffff'; // 设置字体颜色为白色
    this.largeTextElement.style.fontSize = '30px'; // 设置字体大小为100px
    this.largeTextElement.style.fontFamily = 'Rubik Lines'; // 
    this.largeTextElement.style.position = 'fixed'; // 设置固定定位
    this.largeTextElement.style.top = '50%'; // 设置垂直居中
    this.largeTextElement.style.right = '150px'; // 设置距离右侧20px
    this.largeTextElement.style.transform = 'translateY(-50%)'; // 上下居中
    this.largeTextElement.style.zIndex =2;
  }
  resize() {
    this.instance.aspect = this.config.width / this.config.height;
    this.instance.updateProjectionMatrix();

    this.modes.default.instance.aspect = this.config.width / this.config.height;
    this.modes.default.instance.updateProjectionMatrix();

    this.modes.debug.instance.aspect = this.config.width / this.config.height;
    this.modes.debug.instance.updateProjectionMatrix();
    this.updateLargeText();
    
  }

  update() {
    // Update debug orbit controls
    this.modes.debug.orbitControls.update();

    // Apply coordinates
    this.instance.position.copy(this.modes[this.mode].instance.position);
    this.instance.quaternion.copy(this.modes[this.mode].instance.quaternion);
    this.instance.updateMatrixWorld(); // To be used in projection
  }

  destroy() {
    this.modes.debug.orbitControls.destroy();
  }
}
